.rating {
  display: inline-block;
  margin: 0;
}

.product .rating-star {
  --letter-spacing: 0.8;
  --font-size: 1.7;
}

.card-wrapper .rating-star {
  --letter-spacing: 0.7;
  --font-size: 1.4;
}

.rating-star {
  --percent: calc(
    (
        var(--rating) / var(--rating-max) + var(--rating-decimal) *
          var(--font-size) /
          (var(--rating-max) * (var(--letter-spacing) + var(--font-size)))
      ) * 100%
  );
  letter-spacing: calc(var(--letter-spacing) * 1rem);
  font-size: calc(var(--font-size) * 1rem);
  line-height: 1;
  display: inline-block;
  font-family: Times;
  margin: 0;
}

.rating-star::before {
  content: '★★★★★';
  background: linear-gradient(
    90deg,
    var(--color-icon) var(--percent),
    rgba(var(--color-foreground), 0.15) var(--percent)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rating-text {
  display: none;
}

.rating-count {
  display: inline-block;
  margin: 0;
}

@media (forced-colors: active) {
  .rating {
    display: none;
  }

  .rating-text {
    display: block;
  }
}
